import { useState, useEffect } from 'react'

const useBreakpointDetector = (breakpoint: number = 768): boolean => {
    const [isMobile, setIsMobile] = useState<boolean>(false)

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.matchMedia(`(max-width: ${breakpoint}px)`).matches
            setIsMobile(isMobileView)
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [breakpoint])

    return isMobile
}

export default useBreakpointDetector
import React, { useState, useEffect } from 'react'
import QuoteBlock from '../components/QuoteBlock'
import Obituary from '../components/Obituary'
import Timeline from '../components/Timeline'
import Service from '../components/Service'
import FamilyTree from '../components/FamilyTree'
import {Quote, randomizeQuote} from '../utils/CommonUtils'

interface IHomeProps {
    id?: string,
}

export const Home: React.FC<IHomeProps> = ({
    id = 'home',
}: IHomeProps) => {
    const [quoteText, setQuoteText] = useState<string>('')
    const [quoteSource, setQuoteSource] = useState<string>('')

    useEffect(() => {
        const quote: Quote = randomizeQuote()
        setQuoteText(quote.text)
        setQuoteSource(quote.source)
    }, [])

    return (
        <div id={id}>
            <QuoteBlock quoteText={quoteText} quoteSource={quoteSource} />
            <Obituary id='obituary' />
            <Service id='service' />
            <FamilyTree id='familyTree' />
            <Timeline id='timeline' />
        </div>
    )
}

export default Home
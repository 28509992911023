import React from 'react'
import { Link } from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faMagnifyingGlass,
    faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import './NotFound.scss'

interface INotFoundProps {
    id?: string,
}

export const NotFound: React.FC<INotFoundProps> = ({
    id = 'notFound',
}: INotFoundProps) => {
    return (
        <div id={id} className={'not-found-container'}>
            <div className={'not-found-left'}>
                <h1>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className={'icon'} />
                    404
                </h1>
            </div>
            <div className={'not-found-right'}>
                <h2>Sorry!</h2>
                <p>The page you are looking for was not found.</p>
                <div className={'go-back'}>
                    <Link to='/'>
                        <FontAwesomeIcon icon={faChevronLeft} className='link-icon' />
                        Go Back
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound
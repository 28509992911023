import React from 'react'
import './QuoteBlock.scss'

interface IQuoteBlock {
    id?: string,
    quoteText: string,
    quoteSource: string
}
const QuoteBlock: React.FC<IQuoteBlock> = ({
    id = 'quote',
    quoteText,
    quoteSource
}) => {
    return (
        <div id={id} className={'quote-block'}>
            <div className={'quote-icon'}>“</div>
            <p className={'quote-text'}>
                {quoteText}
                <span>{quoteSource}</span>
            </p>
        </div>
    );
};

export default QuoteBlock
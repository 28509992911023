import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {isAfterServiceDate, liveStreamLink} from '../utils/CommonUtils'
import useBreakpointDetector from '../hooks/useBreakpointDetector'
import './Service.scss'

interface IServiceProps {
    id: string,
}

export const Service: React.FC<IServiceProps> = ({
    id,
}: IServiceProps) => {
    const isMobile = useBreakpointDetector(768)

    return (
        <section id={id} className='service'>
            <h2>Funeral</h2>
            <div>
                Ethlyn Flax-George of Hodge’s Creek, Tortola was received into the arms of Jesus on Monday, 8th of April 2024. Ethlyn is survived by her...
                {isMobile ? (
                    <ul className='survivors'>
                        <li>Husband:</li>
                        <ul><li>Roosevelt George</li></ul>

                        <li>Three Children:</li>
                        <ul><li>Janice George-Harris, Gregory George and Michael George</li></ul>

                        <li>Son-in-law:</li>
                        <ul><li>Sheldon Harris</li></ul>

                        <li>Daughters-in-law:</li>
                        <ul><li>Eugenie George and Aquilina George</li></ul>

                        <li>Three Grand Children:</li>
                        <ul><li>Imani George, Malik George and Jasmine George</li></ul>

                        <li>Three Siblings:</li>
                        <ul><li>Sydney Flax of St. Thomas, Daisy Simmonds of Tortola and Wanda Claxton of the United States</li></ul>

                        <li>In-laws:</li>
                        <ul><li>Bernice Lennard, Marcia Belmar, Maxwell George, and Roycie George</li></ul>

                        <li>Nieces and Nephews:</li>
                        <ul><li>Cynthia Campbell, Pearl Flax, Cheryl Simmonds-Molyneaux, Lisa Simmonds-Scatliffe, Dean Simmonds, Carleen Simmonds-Parsons, Ray Eloi, Gary Eloi, and Melissa Eloi</li></ul>

                        <li>Special Friends:</li>
                        <ul><li>Isis Malone, Anna Gracia Joseph, and Doris Rhymer</li></ul>

                        <li>Church Family:</li>
                        <ul><li>The brethren at the Church of God (Holiness)</li></ul>
                    </ul>
                ) : (
                    <div className='table'>
                        <div className='row'>
                            <div className='cell'>Husband:</div>
                            <div className='cell'>Roosevelt George</div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Three Children:
                            </div>
                            <div className='cell'>
                                Janice George-Harris, Gregory George and Michael George
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Son-in-law:
                            </div>
                            <div className='cell'>
                                Sheldon Harris
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Daughters-in-law:
                            </div>
                            <div className='cell'>
                                Eugenie George and Aquilina George
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Three Grand Children:
                            </div>
                            <div className='cell'>
                                Imani George, Malik George and Jasmine George
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Three Siblings:
                            </div>
                            <div className='cell'>
                                Sydney Flax of St. Thomas, Daisy Simmonds of Tortola and Wanda Claxton of the United States
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                In-laws:
                            </div>
                            <div className='cell'>
                                Bernice Lennard, Marcia Belmar, Maxwell George, and Roycie George
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Nieces and Nephews:
                            </div>
                            <div className='cell'>
                                Cynthia Campbell, Pearl Flax, Cheryl Simmonds-Molyneaux, Lisa Simmonds-Scatliffe, Dean Simmonds, Carleen Simmonds-Parsons, Ray Eloi, Gary Eloi, and Melissa Eloi
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Special Friends:
                            </div>
                            <div className='cell'>
                                Isis Malone, Anna Gracia Joseph, and Doris Rhymer
                            </div>
                        </div>
                        <div className='row'>
                            <div className='cell'>
                                Church Family:
                            </div>
                            <div className='cell'>
                                The brethren at the Church of God (Holiness)
                            </div>
                        </div>
                        <br/>
                    </div>
                )}
                ...and her many other dear family and friends both local and abroad.
            </div>
            <p>
                The <span className='highlight'>viewing</span> of the late Ethlyn Flax-George will be on <span className='highlight'>May 10th, 2024 at Davis Funeral Home, starting at 2pm</span> The closed casket <span className='highlight'>funeral service</span> will be held on <span className='highlight'>May 11, 2024 at the East End Church of God Holiness at 10am</span>.
            </p>

            <div>
                {isAfterServiceDate() ? (
                    <div className={'virtual-event'}>
                        <p>
                            A video replay of the event can be viewed below or by clicking the following link...
                            <a href={liveStreamLink} target='_blank' rel='noreferrer' >
                                <FontAwesomeIcon icon={faYoutube} className={'youtube-link-icon'} />
                                Funeral Service
                            </a>
                        </p>
                        <div className={'live-stream'}>
                            <iframe src={liveStreamLink}
                                    title='Funeral of Ethlyn Flax-George'
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                            />
                        </div>
                    </div>
                ) : (
                    <div className={'virtual-event'}>
                        <p>
                            The event can be viewed live using the following link...
                            <a href={liveStreamLink} target='_blank' rel='noreferrer' >
                                <FontAwesomeIcon icon={faYoutube} className={'youtube-link-icon'} />
                                Watch Live Event
                            </a>
                        </p>
                        <div className={'live-stream'}>
                            <iframe src={liveStreamLink}
                                    title='Funeral of Ethlyn Flax-George'
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default Service
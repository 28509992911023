import React from 'react'

// Define the props for the ResponsiveSVG component
interface ResponsiveSVGProps {
    src: string
    vertical?: boolean
}

const ResponsiveSVG: React.FC<ResponsiveSVGProps> = ({
    src,
    vertical= false,
}) => {
    const svgSize = {
        width: '100%',
        height: '100%'
    };

    return (
        <svg width={svgSize.width} height={svgSize.height} viewBox={`0 0 ${vertical ? '500 500' : '2 1'}`}>
            <image href={src} width="100%" height="100%" />
        </svg>
    );
};

export default ResponsiveSVG
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCakeCandles,
    faHeart,
    faChurch,
    faBabyCarriage,
    faHouse,
    faDove,
    faCross,
} from '@fortawesome/free-solid-svg-icons'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './Timeline.scss'


interface ITimelineProps {
    id: string,
}

export const Timeline: React.FC<ITimelineProps> = ({
    id,
}: ITimelineProps) => {

    const grey: string = '#555'
    const accent1: string = '#2056bf'
    const accent2: string = '#fc906c'

    return (
        <section id={id}>
            <h2>Timeline</h2>
            <div className='timeline-wrapper'>
                <VerticalTimeline lineColor={'#ddd'}>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${grey}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${grey}` }}
                        date='April 26, 1943'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${grey}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faCakeCandles} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Birth Day</h3>
                        <p>
                            Ethlyn was born on this day at Peebles Hospital in Road Town, Tortola, British Virgin Islands to Pearl Rosabel Flax (Mother) and Darwin Flax (Father).
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent1}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent1}` }}
                        date='1955'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent1}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faChurch} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Re-birth Day</h3>
                        <p>
                            Ethlyn was reborn a Christian on this day, where she gave her heart to the Lord. The heavens rejoiced with praise. Hallelujah!
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent1}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent1}` }}
                        date='November 10, 1966'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent1}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faHeart} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Wedding Day</h3>
                        <h4 className='vertical-timeline-element-subtitle'>Married to Roosevelt George</h4>
                        <p>
                            "Getting married to Roosevelt was the best thing that I've ever done".
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent2}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent2}` }}
                        date='1966'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent2}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faHouse} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Moved Into New Home</h3>
                        <p>
                            Roosevelt built a house at age 22 in the Valley, Virgin Gorda for his new bride.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent2}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent2}` }}
                        date='1967'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent2}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faBabyCarriage} />}
                    >
                        <h3 className='vertical-timeline-element-title'>First Child Born</h3>
                        <p>
                            Gave birth to Janice.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent2}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent2}` }}
                        date='1969'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent2}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faHouse} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Moved To Road Town</h3>
                        <p>
                            Moved into homestead in Road Town, Tortola.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent2}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent2}` }}
                        date='1970'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent2}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faBabyCarriage} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Second Child Born</h3>
                        <p>
                            Gave birth to Greg
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent2}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent2}` }}
                        date='1974'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent2}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faBabyCarriage} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Last Child Born</h3>
                        <p>
                            Gave birth to Michael
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent2}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent2}` }}
                        date='1975'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent2}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faHouse} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Moved Into New Home</h3>
                        <p>
                            Moved into new home in Hodge's Creek, Tortola
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${accent1}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: `7px solid ${accent1}` }}
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${accent1}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faCross} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Lived for Jesus</h3>
                        <p>
                            For to me, to live is Christ, and to die is gain.<br/>
                            <i style={{textAlign: 'right', display: 'block'}}>-Philippians 1:21</i>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        contentStyle={{ background: `${grey}`, color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid #555' }}
                        date='April 8, 2024'
                        dateClassName={'timeline-date'}
                        iconStyle={{ background: `${grey}`, color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faDove} />}
                    >
                        <h3 className='vertical-timeline-element-title'>Remembrance Date</h3>
                        <p>
                            On this day, Ethlyn Flax-George was received back into the loving arms of <span className='highlight'>Jesus</span>, where she will reside for eternity.
                        </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </div>
        </section>
    )
}

export default Timeline
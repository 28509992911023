import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Layout from './layout/Layout'
import Home from './pages/Home'
import Tributes from './pages/Tributes'
import Photos from './pages/Photos'
import NotFound from './pages/NotFound'
import './App.scss'

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Layout><Home /></Layout>} />
                <Route path="/tributes" element={<Layout><Tributes /></Layout>} />
                {/*<Route path="/photos" element={<Layout><Photos /></Layout>} />*/}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    )
}

export default App;

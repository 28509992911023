import React, {useEffect, useState} from 'react'
import { Snackbar } from '@mui/material'

interface IGrowlProps {
    id: string,
    message: string,
    instance: number,
}

export const Growl: React.FC<IGrowlProps> = ({
    id,
    message,
    instance,
}: IGrowlProps) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>()
    const [snackbarMessage, setSnackbarMessage] = useState<string|undefined>()

    useEffect(() => {
        if (message && instance) {
            showGrowl(message)
        }
    }, [instance])

    const showGrowl = (message:string) => {
        if (showSnackbar) {
            setShowSnackbar(false)
        }
        setSnackbarMessage(message)
        setShowSnackbar(true)
        window.setTimeout(() => {
            setShowSnackbar(false)
        }, 5000)
    }

    const handleCloseMessage = () => {
        setShowSnackbar(false)
    }

    return (
        <Snackbar
            id={id}
            open={showSnackbar}
            onClose={handleCloseMessage}
            style={{ top: 88 }}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} />
    )
}
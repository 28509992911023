import React from 'react'
import ResponsiveSVG from './ResponsiveSVG'
import useBreakpointDetector from '../hooks/useBreakpointDetector'
import FamilyTreeSvg from '../images/family-tree.svg'
import FamilyTreeSvgMobile from '../images/family-tree-mobile.svg'
import './FamilyTree.scss'

interface IFamilyTreeProps {
    id?: string
}

const FamilyTree: React.FC<IFamilyTreeProps> = ({
    id = 'familyTree',
}: IFamilyTreeProps) => {
    const isMobile = useBreakpointDetector(768)

    return (
        <section id={id}>
            <h2>Family Tree</h2>
            <div className='family-tree'>
                <ResponsiveSVG src={isMobile ? FamilyTreeSvgMobile : FamilyTreeSvg} vertical={isMobile} />
            </div>
        </section>
    )
}

export default FamilyTree